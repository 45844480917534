<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">OPEN ACCOUNT REGULAR SAVINGS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_of_member"
                label="Date of Opening"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="account_no"
                label="Account No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="type_of_account"
                dense
                outlined
                label="Type of Account"
                :items="['Personal','Junior','Joint','Corporate']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-card
              flat
              class="mx-3 mt-3"
              v-if="type_of_account=='Corporate'"
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">CORPORATE INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-combobox
                    class="mx-2"
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search_corporate"
                    @keyup="searching_corporate($event.target.value)"
                    @change="get_search_items_info_corporate"
                    :items="search_items_corporate"
                    item-value="id"
                    item-text="business_name"
                    placeholder="Search by Business Name"
                    ref="memberCombobox"
                    dense
                  ></v-combobox>
                </v-col>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="account_name"
                    label="Business Name"
                    dense
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-select
                    v-model="type_of_account_corporate"
                    dense
                    outlined
                    label="Type of Corporate"
                    :items="['Single Proprietorship','Registered Partnership','Registered Corporation','Registered Association/Coop & Organization','Government Owned & Controlled Corporation','Unregistered Association/Organization/Societies/Joint Ventures','Treasurer-in-Trust','Foreign Entity','Others']"
                    :rules="rules.combobox_rule"
                    readonly
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="business_address"
                    label="Business Address"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-row>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      type="date"
                      v-model="date_of_registration"
                      label="Date Of Registration"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="place_of_registration"
                      label="Place Of Registration"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="registration_no"
                      label="Registration Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="tin_no"
                      label="TIN Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="9"
                    cols="12"
                  >
                    <v-select
                      v-model="nature_of_business"
                      dense
                      outlined
                      label="Nature of Business"
                      :items="['Financial Services','Retail,Wholesale','Food & Beverage Industries','Trading','Manufacturing','Leasin/Rental','Transportation','Communication','Real Estate & Construction','Utilities','Others']"
                      readonly
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      type="number"
                      :min="0"
                      v-model="gross_annual_income"
                      label="Gross Annual Income"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="telephone_no"
                      label="Telephone Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="fax_no"
                      label="Fax Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="email_address"
                      label="Email Address"
                      dense
                      outlined
                      :rules="rules.default_max_75_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="9"
                    cols="12"
                  >
                    <v-text-field
                      v-model="authorized_representative_name"
                      label="Authorize Person Name"
                      dense
                      outlined
                      :rules="rules.default_max_100_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="authorized_representative_contact_no"
                      label="Authorize Person Contact Number"
                      dense
                      outlined
                      :rules="rules.default_max_100_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="6"
                    cols="12"
                  >
                    <v-select
                      v-model="financial_details_gscc"
                      dense
                      outlined
                      label="Have Existing GSCC Account(s)"
                      :items="['Savings','Current','Time Account','Consumer Loan','Commercial Loan','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="6"
                    cols="12"
                  >
                    <v-select
                      v-model="financial_details_other_banks"
                      dense
                      outlined
                      label="Have Existing Other Bank Account(s)"
                      :items="['Savings','Current','Time Account','Consumer Loan','Commercial Loan','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION MEMBER 1</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-combobox
                    class="mx-2"
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search"
                    @keyup="searching($event.target.value)"
                    @change="get_search_items_info"
                    :items="search_items"
                    item-value="id"
                    item-text="name"
                    placeholder="Search by (Lastname/Firstname/CMF #)"
                    ref="memberCombobox"
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="tin_no_personal"
                      dense
                      outlined
                      label="Tax ID Number (TIN)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="sss_no"
                      dense
                      outlined
                      label="SSS Number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="no_of_dependents"
                      dense
                      outlined
                      label="No. Of Dependents"
                      type="number"
                      min="0"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="educational_attainment"
                      dense
                      outlined
                      label="Educational Attainment"
                      :items="['Elementary Level','Secondary Level','College Level','College Graduate']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="present_address"
                      dense
                      outlined
                      label="Present Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="residence_is"
                      dense
                      outlined
                      label="Residence is"
                      :items="['Owned','Rented','Mortgaged','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-if="residence_is==='Others'"
                      v-model="residence_specify"
                      dense
                      outlined
                      label="Specify (if others)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="citizenship"
                      dense
                      outlined
                      label="Citizenship"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no"
                      dense
                      outlined
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="email_address_personal"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              v-if="type_of_account=='Junior'||type_of_account=='Joint' ||type_of_account=='Corporate'"
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION MEMBER 2</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-combobox
                    class="mx-2"
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search2"
                    @keyup="searching2($event.target.value)"
                    @change="get_search_items_info2"
                    :items="search_items2"
                    item-value="id"
                    item-text="name"
                    placeholder="Search by (Lastname/Firstname/CMF #)"
                    ref="memberCombobox"
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name2"
                      label="Last Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name2"
                      label="First Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name2"
                      label="Middle Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth2"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth2"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender2"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="tin_no_personal2"
                      dense
                      outlined
                      label="Tax ID Number (TIN)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="sss_no2"
                      dense
                      outlined
                      label="SSS Number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="religion2"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status2"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="no_of_dependents2"
                      dense
                      outlined
                      label="No. Of Dependents"
                      type="number"
                      min="0"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="educational_attainment2"
                      dense
                      outlined
                      label="Educational Attainment"
                      :items="['Elementary Level','Secondary Level','College Level','College Graduate']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="present_address2"
                      dense
                      outlined
                      label="Present Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="residence_is2"
                      dense
                      outlined
                      label="Residence is"
                      :items="['Owned','Rented','Mortgaged','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-if="residence_is2==='Others'"
                      v-model="residence_specify2"
                      dense
                      outlined
                      label="Specify (if others)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address2"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code2"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="citizenship2"
                      dense
                      outlined
                      label="Citizenship"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no2"
                      dense
                      outlined
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="email_address_personal2"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              v-if="type_of_account=='Corporate'"
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION MEMBER 3</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-combobox
                    class="mx-2"
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search3"
                    @keyup="searching3($event.target.value)"
                    @change="get_search_items_info3"
                    :items="search_items3"
                    item-value="id"
                    item-text="name"
                    placeholder="Search by (Lastname/Firstname/CMF #)"
                    ref="memberCombobox"
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name3"
                      label="Last Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name3"
                      label="First Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name3"
                      label="Middle Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth3"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth3"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender3"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="tin_no_personal3"
                      dense
                      outlined
                      label="Tax ID Number (TIN)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="sss_no3"
                      dense
                      outlined
                      label="SSS Number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="religion3"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status3"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="no_of_dependents3"
                      dense
                      outlined
                      label="No. Of Dependents"
                      type="number"
                      min="0"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="educational_attainment3"
                      dense
                      outlined
                      label="Educational Attainment"
                      :items="['Elementary Level','Secondary Level','College Level','College Graduate']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="present_address3"
                      dense
                      outlined
                      label="Present Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="residence_is3"
                      dense
                      outlined
                      label="Residence is"
                      :items="['Owned','Rented','Mortgaged','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-if="residence_is3==='Others'"
                      v-model="residence_specify3"
                      dense
                      outlined
                      label="Specify (if others)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address3"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code3"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="citizenship3"
                      dense
                      outlined
                      label="Citizenship"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no3"
                      dense
                      outlined
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="email_address_personal3"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col md="4" cols="12">
                <v-avatar
                  rounded
                  size="300"
                  class="me-6"
                >
                  <v-img :src="cropped"></v-img>
                </v-avatar>
                <v-btn
                  color="primary"
                  class="me-3 mt-5"
                  @click="$refs.refInputEl.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Valid ID & Signature Member 1</span>
                </v-btn>
                <input
                  ref="refInputEl"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl.value = ''"
                  v-on:change="croppie"
                />
              </v-col>
              <v-col md="4" cols="12"
                     v-if="type_of_account==='Joint'||type_of_account==='Corporate'">
                <v-avatar
                  rounded
                  size="300"
                  class="me-6"
                >
                  <v-img :src="cropped_bus"></v-img>
                </v-avatar>
                <v-btn
                  color="primary"
                  class="me-3 mt-5"
                  @click="$refs.refInputEl_bus.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Valid ID & Signature Member 2</span>
                </v-btn>
                <input
                  ref="refInputEl_bus"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl_bus.value = ''"
                  v-on:change="croppie_bus"
                />
              </v-col>
              <v-col md="4" cols="12" v-if="type_of_account==='Corporate'">
                <v-avatar
                  rounded
                  size="300"
                  class="me-6"
                >
                  <v-img :src="cropped_home"></v-img>
                </v-avatar>

                <v-btn
                  color="primary"
                  class="me-3 mt-5"
                  @click="$refs.refInputEl_home.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Valid ID & Signature Member 3</span>
                </v-btn>

                <input
                  ref="refInputEl_home"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl_home.value = ''"
                  v-on:change="croppie_home"
                />
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-if="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      avatarImg: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_1: '',
      cropped: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_2: '',
      cropped_bus: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_3: '',
      cropped_home: require('@/assets/images/avatars/1.png'),

      type_of_account: '',
      date_of_member: '',
      account_name: '',
      account_no: '',

      search: '',
      search_member: '',
      search_items: [],
      search2: null,
      search_member2: '',
      search_items2: [],
      search3: null,
      search_member3: '',
      search_items3: [],
      search_corporate: null,
      search_items_corporate: [],


      last_name: '',
      first_name: '',
      middle_name: '',
      permanent_address: '',
      date_of_birth: '',
      place_of_birth: '',
      gender: '',
      tin_no_personal: '',
      sss_no: '',
      religion: '',
      civil_status: '',
      no_of_dependents: 0,
      educational_attainment: '',
      present_address: '',
      residence_is: '',
      residence_specify: '',
      zip_code: '',
      citizenship: '',
      contact_no: '',
      email_address_personal: '',

      last_name2: '',
      first_name2: '',
      middle_name2: '',
      permanent_address2: '',
      date_of_birth2: '',
      place_of_birth2: '',
      gender2: '',
      tin_no_personal2: '',
      sss_no2: '',
      religion2: '',
      civil_status2: '',
      no_of_dependents2: 0,
      educational_attainment2: '',
      present_address2: '',
      residence_is2: '',
      residence_specify2: '',
      zip_code2: '',
      citizenship2: '',
      contact_no2: '',
      email_address_personal2: '',

      last_name3: '',
      first_name3: '',
      middle_name3: '',
      permanent_address3: '',
      date_of_birth3: '',
      place_of_birth3: '',
      gender3: '',
      tin_no_personal3: '',
      sss_no3: '',
      religion3: '',
      civil_status3: '',
      no_of_dependents3: 0,
      educational_attainment3: '',
      present_address3: '',
      residence_is3: '',
      residence_specify3: '',
      zip_code3: '',
      citizenship3: '',
      contact_no3: '',
      email_address_personal3: '',

      type_of_account_corporate: '',
      business_address: '',
      date_of_registration: '',
      place_of_registration: '',
      registration_no: '',
      tin_no: '',
      nature_of_business: '',
      gross_annual_income: 0,
      telephone_no: '',
      fax_no: '',
      email_address: '',
      authorized_representative_name: '',
      authorized_representative_contact_no: '',
      financial_details_gscc: '',
      financial_details_other_banks: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.account_no_data()
    },
    data() {
      return initialState()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member2: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member2 == '') {
            this.search_items2 = []
          } else {
            this.searching_search_items2(this.search_member2)
          }
        }, 500)
      },
      search_member3: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member3 == '') {
            this.search_items3 = []
          } else {
            this.searching_search_items3(this.search_member3)
          }
        }, 500)
      },
      type_of_account: function () {
        this.account_name = ''
        if (this.type_of_account === 'Personal' || this.type_of_account === 'Junior') {
          this.search2 = null
          this.search3 = null
        } else if (this.type_of_account === 'Joint') {
          this.search3 = null
        }
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members', 'search_all_members','search_corporate_account']),
      ...mapActions('regular_savings_info', ['register_new_open_acount_regular_savings']),
      reset() {
        Object.assign(this.$data, initialState())

        this.account_no_data()
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage_mem_1 = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      croppie_bus(e) {
        this.show_bus = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_bus = this.croppieImage_mem_2 = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_home(e) {
        this.show_home = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_mem_3 = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      account_no_data() {
        this.account_no = 'GSCC-' + moment().year() + '-' + moment().seconds() + '' + moment().minutes() + '' + moment().hours() + '-' + moment().date() + '' + (moment().month() + 1)
      },
      searching(value) {
        this.search_member = value
      },
      searching2(value) {
        this.search_member2 = value
      },
      searching3(value) {
        this.search_member3 = value
      },
      searching_search_items(value) {
        this.search_all_members({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      searching_search_items2(value) {
        this.search_all_members({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items2 = response.data
          })
      },
      searching_search_items3(value) {
        this.search_all_members({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items3 = response.data
          })
      },
      searching_corporate(value) {
        this.search_corporate_account({
          search_word: value
        })
          .then(response => {
            this.search_items_corporate = response.data
          })
      },
      get_search_items_info2() {
        if (this.search_items2.length > 0) {
          var index = this.search_items2.map(function (x) {
            return x.id;
          }).indexOf(this.search2.id)
          if (index != -1) {
            this.last_name2 = this.search_items2[index].member.last_name
            this.first_name2 = this.search_items2[index].member.first_name
            this.middle_name2 = this.search_items2[index].member.middle_name
            this.permanent_address2 = this.search_items2[index].member.permanent_address
            this.gender2 = this.search_items2[index].member.gender
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.last_name = this.search_items[index].member.last_name
            this.first_name = this.search_items[index].member.first_name
            this.middle_name = this.search_items[index].member.middle_name
            this.permanent_address = this.search_items[index].member.permanent_address
            this.gender = this.search_items[index].member.gender
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info_corporate() {
        if (this.search_items_corporate.length > 0) {
          var index = this.search_items_corporate.map(function (x) {
            return x.id;
          }).indexOf(this.search_corporate.id)
          if (index != -1) {
            this.account_name = this.search_items_corporate[index].business_name
            this.type_of_account_corporate = this.search_items_corporate[index].type_of_account
            this.business_address = this.search_items_corporate[index].business_address
            this.date_of_registration = this.search_items_corporate[index].date
            this.place_of_registration = this.search_items_corporate[index].place_of_registration
            this.registration_no = this.search_items_corporate[index].registration_no
            this.tin_no = this.search_items_corporate[index].tin_no
            this.nature_of_business = this.search_items_corporate[index].nature_of_business
            this.gross_annual_income = this.search_items_corporate[index].gross_annual_income
            this.telephone_no = this.search_items_corporate[index].telephone_no
            this.fax_no = this.search_items_corporate[index].fax_no
            this.email_address = this.search_items_corporate[index].email_address
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info3() {
        if (this.search_items3.length > 0) {
          var index = this.search_items3.map(function (x) {
            return x.id;
          }).indexOf(this.search3.id)
          if (index != -1) {
            this.last_name3 = this.search_items3[index].member.last_name
            this.first_name3 = this.search_items3[index].member.first_name
            this.middle_name3 = this.search_items3[index].member.middle_name
            this.permanent_address3 = this.search_items3[index].member.permanent_address
            this.gender3 = this.search_items3[index].member.gender
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },

      save_loan_application() {
        this.saving = true
        this.alert = false
        var flag = false
        if (moment(this.date_of_member, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
          || moment(this.date_of_member, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
          flag = true
        } else {
          var compareDate = moment(this.date_of_member, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_of_member = ''
          this.alert = true
          this.alert_message = 'Date of Opening is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          var proceed = true;
          var account_nameeee = this.last_name + ', ' + this.first_name + ' ' + this.middle_name;
          if (this.type_of_account === 'Junior') {
            if (this.croppieImage_mem_1 === '') {
              this.alert = true
              this.alert_message = 'Please Upload Valid ID with Signature of Guardian'
              this.saving = false
              proceed = false
            }
          } else {
            if (this.croppieImage_mem_1 === '') {
              this.alert = true
              this.alert_message = 'Please Upload Valid ID with Signature of Member 1'
              this.saving = false
              proceed = false
            }
          }
          if ((this.type_of_account === 'Joint')) {
            account_nameeee = this.last_name + ', ' + this.first_name + ' ' + this.middle_name
              + ' & ' + this.last_name2 + ', ' + this.first_name2 + ' ' + this.middle_name2;
            if (this.croppieImage_mem_2 === '') {
              this.alert = true
              this.alert_message = 'Please Upload Valid ID with Signature of Member 2'
              this.saving = false
              proceed = false
            }
          }
          if ((this.type_of_account === 'Corporate')) {
            account_nameeee = this.account_name
            if (this.croppieImage_mem_2 === '') {
              this.alert = true
              this.alert_message = 'Please Upload Valid ID with Signature of Member 2'
              this.saving = false
              proceed = false
            }
          }
          if (proceed) {
            if (this.$refs.form.validate()) {
              const data = new FormData()
              data.append('branch_id', this.branch_id);
              data.append('member_id', this.search.id);

              var mem_2 = '';
              var mem_3 = '';
              if (this.search2 != null) {
                mem_2 = this.search2.id;
              }
              if (this.search3 != null) {
                mem_3 = this.search3.id;
              }

              data.append('member_id_2', mem_2);
              data.append('member_id_3', mem_3);
              data.append('category', this.type_of_account);
              data.append('account_name', account_nameeee);
              data.append('account_no', this.account_no);
              data.append('date_of_member', this.date_of_member);
              data.append('month_of', this.month_of);
              data.append('member_1_valid_signature', this.croppieImage_mem_1);
              data.append('member_2_valid_signature', this.croppieImage_mem_2);
              data.append('member_3_valid_signature', this.croppieImage_mem_3);

              data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
              data.append('place_of_birth', this.place_of_birth);
              data.append('gender', this.gender);
              data.append('tin_no_personal', this.tin_no_personal);
              data.append('sss_no', this.sss_no);
              data.append('religion', this.religion);
              data.append('civil_status', this.civil_status);
              data.append('no_of_dependents', this.no_of_dependents);
              data.append('educational_attainment', this.educational_attainment);
              data.append('present_address', this.present_address);
              data.append('residence_is', this.residence_is);
              data.append('residence_specify', this.residence_specify);
              data.append('permanent_address', this.permanent_address);
              data.append('zip_code', this.zip_code);
              data.append('citizenship', this.citizenship);
              data.append('contact_no', this.contact_no);
              data.append('email_address_personal', this.email_address_personal);

              data.append('date_of_birth2', moment(this.date_of_birth2, 'YYYY-MM-DD').format('MMMM D, YYYY'));
              data.append('place_of_birth2', this.place_of_birth2);
              data.append('gender2', this.gender2);
              data.append('tin_no_personal2', this.tin_no_personal2);
              data.append('sss_no2', this.sss_no2);
              data.append('religion2', this.religion2);
              data.append('civil_status2', this.civil_status2);
              data.append('no_of_dependents2', this.no_of_dependents2);
              data.append('educational_attainment2', this.educational_attainment2);
              data.append('present_address2', this.present_address2);
              data.append('residence_is2', this.residence_is2);
              data.append('residence_specify2', this.residence_specify2);
              data.append('permanent_address2', this.permanent_address2);
              data.append('zip_code2', this.zip_code2);
              data.append('citizenship2', this.citizenship2);
              data.append('contact_no2', this.contact_no2);
              data.append('email_address_personal2', this.email_address_personal2);

              data.append('date_of_birth3', moment(this.date_of_birth3, 'YYYY-MM-DD').format('MMMM D, YYYY'));
              data.append('place_of_birth3', this.place_of_birth3);
              data.append('gender3', this.gender3);
              data.append('tin_no_personal3', this.tin_no_personal3);
              data.append('sss_no3', this.sss_no3);
              data.append('religion3', this.religion3);
              data.append('civil_status3', this.civil_status3);
              data.append('no_of_dependents3', this.no_of_dependents3);
              data.append('educational_attainment3', this.educational_attainment3);
              data.append('present_address3', this.present_address3);
              data.append('residence_is3', this.residence_is3);
              data.append('residence_specify3', this.residence_specify3);
              data.append('permanent_address3', this.permanent_address3);
              data.append('zip_code3', this.zip_code3);
              data.append('citizenship3', this.citizenship3);
              data.append('contact_no3', this.contact_no3);
              data.append('email_address_personal3', this.email_address_personal3);

              data.append('type_of_account_corporate', this.type_of_account_corporate);
              data.append('business_address', this.business_address);
              data.append('date_of_registration', this.date_of_registration);
              data.append('place_of_registration', this.place_of_registration);
              data.append('registration_no', this.registration_no);
              data.append('tin_no', this.tin_no);
              data.append('nature_of_business', this.nature_of_business);
              data.append('gross_annual_income', this.gross_annual_income);
              data.append('telephone_no', this.telephone_no);
              data.append('fax_no', this.fax_no);
              data.append('email_address', this.email_address);
              data.append('authorized_representative_name', this.authorized_representative_name);
              data.append('authorized_representative_contact_no', this.authorized_representative_contact_no);
              data.append('financial_details_gscc', this.financial_details_gscc);
              data.append('financial_details_other_banks', this.financial_details_other_banks);

              this.register_new_open_acount_regular_savings(data)
                .then(response => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: response.data,
                  })
                  this.reset()
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  console.log(error)
                  this.saving = false
                })
            } else {
              this.alert = true
              this.alert_message = 'Please Fill up the field/s'
              this.saving = false
            }
          }
        }
      }
    }
  }
</script>
